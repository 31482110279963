<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClickTab">
      <el-tab-pane label="存款列表" name="first" :key="'first'">
        <el-card>
          <el-row :gutter="20">
            <el-col :span="7">
              <el-input placeholder="存款名字">
                <el-button slot="append" icon="el-icon-search"></el-button>
              </el-input>
            </el-col>
            <el-col :span="7">
              <el-input placeholder="是否齐全">
                <el-button slot="append" icon="el-icon-search"></el-button>
              </el-input>
            </el-col>
            <el-col :span="4">
              <el-button type="primay" @click="getBankList()">查询</el-button>
              <el-button type="primay">重置</el-button>
            </el-col>
          </el-row>
          <!-- 按钮区 -->
          <el-row :gutter="20" style="margin:9px 2px">
            <el-button type="success" icon="el-icon-add" size="min" @click="addBankShow">新增存款卡</el-button>
            <el-button
              type="success"
              icon="el-icon-edit"
              size="min"
              @click="editBankShow"
              disabled
            >编辑</el-button>
            <!-- <el-button
              type="success"
              icon="el-icon-edit"
              size="min"
              @click="editFundMoneyShow"
            >调整基金池金额</el-button>-->
            <el-button
              type="warning"
              icon="el-icon-edit"
              size="min"
              @click="directFundMoneyShow"
            >交易金额</el-button>
            <el-button type="warning" @click="noMustRetuenButton(1)" disabled>测试</el-button>
            <el-button type="success" @click="BankLogShow" disabled>查看流水</el-button>
            <el-button type="danger" @click="shiftMoneyShow" disabled>资金转移</el-button>
            <el-button type="danger" @click="shiftMoneyShow" disabled>更新收益</el-button>
            <el-button type="danger" disabled>删除</el-button>
            <el-button type="danger" disabled>查看详情</el-button>
            <el-button type="danger" @click="showNextButton">展示预测</el-button>
          </el-row>
          <!-- 列表区域 -->
          <el-table :data="bankList" border show-summary :row-class-name="tableRowClassName">
            <el-table-column label="序号" align="center" width="65">
              <template slot-scope="scope">
                <el-radio
                  :label="scope.$index"
                  v-model="radio"
                  @change.native="getCurrentRow(scope.row)"
                ></el-radio>
              </template>
            </el-table-column>

            <el-table-column label="卡类型" prop="bankType" width="75" :formatter="bankTypeFormat" />
            <el-table-column label="总行名称" prop="bankName" width="100" />
            <el-table-column label="支行名称" prop="subbranch" />
            <el-table-column label="实际存入的资金" prop="depositedMoney" />
            <el-table-column
              label="分配金额"
              prop="depositedMoney"
              width="140"
              :render-header="renderHeader"
            >
              <template slot-scope="scope">
                <el-button @click="AllotShow(scope.row)">{{scope.row.distributeMoney}}</el-button>
              </template>
            </el-table-column>
            <el-table-column
              label="预支金额"
              prop="returnMoney"
              width="140"
              :render-header="renderHeader"
            >
              <template slot-scope="scope">
                <el-button slot="reference" @click="noRetuenButton(scope.row)" disabled>预支金额</el-button>
              </template>
            </el-table-column>
            <el-table-column
              label="差异金额"
              prop="cancelMoney"
              width="120"
              :render-header="renderHeader"
            >
              <template slot-scope="scope">
                <el-button @click="differMoneyButton(scope.row)" disabled>{{scope.row.differMoney}}</el-button>
              </template>
            </el-table-column>
            <!-- <el-table-column
              label="作废金额"
              prop="cancelMoney"
              width="120"
              :render-header="renderHeader"
            >
              <template slot-scope="scope">
                <el-button @click="noRetuenButton(scope.row)">作废金额</el-button>
              </template>
            </el-table-column>-->
            <!-- <el-table-column label="预估借走回来的资金--一定会归还 4000" prop="preBorrowMoney" />
               <el-table-column label="不一定归还金额--不一定归还 1000" prop="cancelMoney" width="75">
              <template slot-scope="scope">
                <span
                  style="color:#108ee9;cursor:pointer;"
                  @click="noMustRetuenButton(scope.row.id)"
                >{{scope.row.isNoRetureMoney}}</span>
              </template>
            </el-table-column>-->
            <el-table-column
              label="借走金额"
              prop="cancelMoney"
              width="120"
              :render-header="renderHeader"
            >
              <template slot-scope="scope">
                <el-button @click="noMustRetuenButton(scope.row)" disabled>{{scope.row.borrowMoney}}</el-button>
              </template>
            </el-table-column>
            <!-- <el-table-column label="其他金额" prop="otherMoney" /> -->
            <el-table-column label="状态" prop="status" width="50" />
            <el-table-column label="实际可能总收入金额" prop="preDepositedMoney" />
            <el-table-column label="预计总入资金" prop="preSumDepositedMoney" />
            <el-table-column label="查看流水" prop="cancelMoney" width="120">
              <template slot-scope="scope">
                <el-button size="small" @click="BankLogShow(scope.row)">查看流水</el-button>
              </template>
            </el-table-column>
            <el-table-column label="用途" prop="purpose" />
          </el-table>
        </el-card>
      </el-tab-pane>
      <el-tab-pane label="借用列表" ref="child3" name="three" :key="'three'">
        暂未开放
        <child3 v-if="!error"></child3>
      </el-tab-pane>
      <el-tab-pane label="使用列表" ref="child2" name="second" :key="'second'">
        暂未开放
        <child2 v-if="!error"></child2>
      </el-tab-pane>

      <el-tab-pane label="预收回款" ref="child4" name="four" :key="'four'">
        暂未开放
        <child4 v-if="!error"></child4>
      </el-tab-pane>
    </el-tabs>
    <AddBank ref="addFrom"></AddBank>
    <EditBank ref="editFrom"></EditBank>
    <EditFundMoney ref="EditFundMoney"></EditFundMoney>
    <directFundMoney ref="directFundMoneyFrom"></directFundMoney>
    <shiftMoney ref="shiftMoneyFrom"></shiftMoney>
    <BankLog ref="BankLogFrom"></BankLog>
    <borrowLog ref="borrowLogFrom"></borrowLog>
    <allot ref="allotFrom"></allot>
    <noBorrowLog ref="noBorrowLogFrom"></noBorrowLog>
    <showNext ref="showNextFrom"/>
  </div>
</template>
<script>
import bankAnticipate from "./bankAnticipate";
import AddBank from "./Info/addBank";
import EditBank from "./Info/editBank";
import EditFundMoney from "./Info/editFundMoney";
import bankEstimateList from "./bankEstimateList";
import directFundMoney from "./Info/directFundMoney";
import shiftMoney from "./Info/shiftMoney";
import { getBankInfoList } from "@/api/capital/bank/bank";
import BankLog from "./Info/BankLog";
import bankBorrow from "./bankBorrow";
import borrowLog from "./Borrow/borrowLog";
import allot from "./Info/allotShow";
import noBorrowLog from "./Borrow/noBorrowLog";
import { getWorkEnumList } from "@/api/commonApi/commonInternal/commonInternal";

import showNext from "./Info/showNext";
export default {
  name: "bankList",
  components: {
    child2: bankAnticipate,
    child3: bankBorrow,
    child4: bankEstimateList,
    AddBank,
    EditBank,
    EditFundMoney,
    directFundMoney,
    shiftMoney,
    BankLog,
    borrowLog,
    allot,
    noBorrowLog,
    showNext
  },
  data() {
    return {
      error: true,
      //默认第一个选项卡
      activeName: "first",
      bankList: [],
      id: null,
      radio: null,
      query: {},
      bankType: null,
      allotMonye: null,
      attributionKeyList:[],
      bankTypeOption: [],
      queryParams: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
    };
  },
  watch: {
    "queryParams.currentPage"(val) {
      this.selectDataListButton();
    },
    "queryParams.pageSize"(val) {
      this.selectDataListButton();
    },
  },
  created() {
    this.getBankList();
    this.borrowMethid();
    this.getFundEnumListButton();
    this.attributionKeyListButton()
  },
  methods: {
    parentMethod() {
      this.$refs.child2.getBankAnticipateList();
    },
    borrowMethid() {},
    getCurrentRow(row) {
      // 获取选中数据   row表示选中这一行的数据，可以从里面提取所需要的值
      this.id = row.id;
      this.bankType = row.bankType;
      this.templateSelection = row;
      this.allotMonye = row.depositedIncomeMoney;
    },
    getBankList() {
      getBankInfoList({}).then(res => {
        if (res.code === 500) return this.$message.error("获取基金信息失败");
        this.bankList = res.result.records;
      });
    },
    getFundEnumListButton() {
     this.selectDictItemSyn("xunPersonal", "bank_type").then(
        response => {
          this.bankTypeOption = response.result;
        }
      );
    },
    addBankShow() {
      this.$refs.addFrom.showFund();
    },
    editBankShow() {
      if ((this.id === null) | (this.id === undefined))
        return this.$message.error("请选择一条数据");
      this.$refs.editFrom.showFund(this.id);
    },
    editFundMoneyShow() {
      if ((this.id === null) | (this.id === undefined))
        return this.$message.error("请选择一条数据");
      if (this.bankType !== 1)
        return this.$message.error("只有基金类型才能调整");
      this.$refs.EditFundMoney.showFund(this.id);
    },
    directFundMoneyShow() {
      if ((this.id === null) | (this.id === undefined))
        return this.$message.error("请选择一条数据");
      this.$refs.directFundMoneyFrom.showFund(this.id);
    },
    shiftMoneyShow() {
      this.$refs.shiftMoneyFrom.show(this.id);
    },
    BankLogShow(row) {
      this.$refs.BankLogFrom.show(row.id);
    },
    handleClickTab(tab, event) {
      if (tab.name === "first") {
        this.getBankList();
      }
      if (tab.name === "three") {
        tab.$children[0].getBankBorrowButton();
      }
    },
    details(id) {
      this.$refs.borrowLogFrom.show(id);
    },
    noRetuenButton(row) {
      this.$refs.borrowLogFrom.show(row.id, 4);
    },
    differMoneyButton(row) {
      this.$refs.borrowLogFrom.show(row.id, 4);
    },
    noMustRetuenButton(row) {
      this.$refs.borrowLogFrom.show(row.id, 2);
    },
    AllotShow(row) {
      this.$refs.allotFrom.show(row.id, row.depositedMoney);
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.depositedMoney != row.distributeMoney) {
        return "warning-row";
      } else if (rowIndex === 3) {
        return "success-row";
      }
      return "";
    },

    renderHeader(h, { column, $index }) {
      // h即为cerateElement的简写，具体可看vue官方文档
      return h("div", [
        h("span", column.label),
        h(
          "i",
          {
            class: "el-icon-refresh",
            style: "color:#409eff;margin-left:5px;pointer-events: auto;",
            on: {
              click: () => {
                //一定要用箭头函数
                this.switchClick($index, column);
              }
            }
          },
          ""
        )
      ]);
    },
    switchClick(index, data) {
      var mesText = null;
      if (index == 5) {
        mesText = "这里是指将卡中的金额分配详情";
      } else if (index == 6) {
        mesText = "自己提前使用，会归还的";
      } else if (index == 7) {
        mesText =
          "预计总入跟 实际总入的金额只差  可能是因为别人还钱还少了等===包括还少了 以及作废了的金额";
      } else if (index == 8) {
        mesText = "别人借走的钱具体明细列表";
      }
      this.$message({ message: mesText, type: "success", center: true });
    },
    bankTypeFormat(row) {
      return this.selectDictLabel(this.bankTypeOption, row.bankType);
    },
    attributionKeyListButton() {
      getWorkEnumList({ enumName: "AssignEnum" }).then((resp) => {
        this.attributionKeyList = resp.result;
      });
    },
    showNextButton(){
      this.$refs.showNextFrom.show(this.id);
    }
  }
};
</script>
<style scoped>
.el-table .warning-row {
  background: #ff1;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>
